<div [class]="'condition_container condition_container_' + componentID">
  <div class="condition_viewer" *ngIf="!showEditMode">
    <span class="open_bracket">(</span>

    <ng-container *ngIf="condition.criteria[0]">
      <condition-container
        *ngIf="condition.criteria[0].type == 'condition'"
        [showEditMode]="showEditMode"
        [selectedDatapoint]="selectedDatapoint"
        [selectedDataCategory]="selectedDataCategory"
        [level3DataCategory]="level3DataCategory"
        [parentCondition]="condition"
        [conditionIdx]="0"
        [condition]="condition.criteria[0]"
      ></condition-container>

      <ng-container *ngIf="condition.criteria[0].type == 'criterion'">
        <div class="criterion">
          <span class="label">{{ resolveLabel({ token: condition.criteria[0], deployed: true }) }}</span>
          <span class="condition">{{ getConditionLocale(condition.criteria[0].condition) }}</span>
          <span class="value">{{ condition.criteria[0].value }}</span>
        </div>
      </ng-container>
    </ng-container>

    <div class="line_break" *ngIf="condition.criteria[0].type == 'condition'"></div>

    <ng-container *ngIf="condition.comparator != 'none'">
      <span class="comparator">
        {{
          locale(
            'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.' +
              conditionComparator.toLowerCase()
          )?.toUpperCase() || conditionComparator
        }}
      </span>
    </ng-container>

    <ng-container *ngIf="condition.criteria[1]">
      <div class="line_break" *ngIf="condition.criteria[1].type == 'condition'"></div>

      <condition-container
        *ngIf="condition.criteria[1].type == 'condition'"
        [showEditMode]="showEditMode"
        [selectedDatapoint]="selectedDatapoint"
        [selectedDataCategory]="selectedDataCategory"
        [level3DataCategory]="level3DataCategory"
        [parentCondition]="condition"
        [conditionIdx]="1"
        [condition]="condition.criteria[1]"
      ></condition-container>

      <ng-container *ngIf="condition.criteria[1].type == 'criterion'">
        <div class="criterion">
          <span class="label">{{ resolveLabel({ token: condition.criteria[1], deployed: true }) }}</span>
          <span class="condition">{{ getConditionLocale(condition.criteria[1].condition) }}</span>
          <span class="value">{{ condition.criteria[1].value }}</span>
        </div>
      </ng-container>
    </ng-container>

    <span class="close_bracket">)</span>
  </div>

  <div *ngIf="condition.criteria.length == 2 && showEditMode" class="condition_pill_container">
    <div *ngIf="!isRoot" class="line horizontal left"></div>
    <div class="line horizontal top right"></div>
    <div class="line horizontal bottom right"></div>
    <div class="line vertical top"></div>
    <div class="line vertical bottom"></div>
    <div class="condition_pill">
      {{
        locale(
          'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.' +
            conditionComparator.toLowerCase()
        )?.toUpperCase() || conditionComparator
      }}
    </div>
  </div>

  <div class="rules_container" *ngIf="showEditMode">
    <ng-container *ngFor="let criterion of condition.criteria; let criterionIdx = index">
      <condition-container
        *ngIf="criterion.type == 'condition'"
        [selectedDatapoint]="selectedDatapoint"
        [selectedDataCategory]="selectedDataCategory"
        [level3DataCategory]="level3DataCategory"
        [parentCondition]="condition"
        [conditionIdx]="criterionIdx"
        [condition]="criterion"
      ></condition-container>
      <div *ngIf="criterion.type == 'criterion'" class="rule_container">
        <div class="inputs_container">
          <i class="las la-grip-lines edit_control drag"></i>

          <div class="datapoint_input_container">
            <svg
              *ngIf="criterion.datapoint && criterion.datapoint.datatype == 'STRING'"
              class="datapoint_icon datapoint_text_icon"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M15.9375 14.625H15.1145L10.5192 1.88754C10.4435 1.66512 10.3001 1.47198 10.109 1.33522C9.918 1.19847 9.68893 1.12495 9.45398 1.125H7.79602C7.56107 1.12495 7.332 1.19847 7.14096 1.33522C6.94991 1.47198 6.80648 1.66512 6.73078 1.88754L2.13551 14.625H1.3125C1.16332 14.625 1.02024 14.6843 0.914752 14.7898C0.809263 14.8952 0.75 15.0383 0.75 15.1875L0.75 16.3125C0.75 16.4617 0.809263 16.6048 0.914752 16.7102C1.02024 16.8157 1.16332 16.875 1.3125 16.875H5.8125C5.96168 16.875 6.10476 16.8157 6.21025 16.7102C6.31574 16.6048 6.375 16.4617 6.375 16.3125V15.1875C6.375 15.0383 6.31574 14.8952 6.21025 14.7898C6.10476 14.6843 5.96168 14.625 5.8125 14.625H5.12414L5.94328 12.375H11.3067L12.1259 14.625H11.4375C11.2883 14.625 11.1452 14.6843 11.0398 14.7898C10.9343 14.8952 10.875 15.0383 10.875 15.1875V16.3125C10.875 16.4617 10.9343 16.6048 11.0398 16.7102C11.1452 16.8157 11.2883 16.875 11.4375 16.875H15.9375C16.0867 16.875 16.2298 16.8157 16.3352 16.7102C16.4407 16.6048 16.5 16.4617 16.5 16.3125V15.1875C16.5 15.0383 16.4407 14.8952 16.3352 14.7898C16.2298 14.6843 16.0867 14.625 15.9375 14.625ZM6.96738 9.5625L8.625 5.01012L10.2826 9.5625H6.96738Z"
                fill="#374659"
              />
            </svg>
            <svg
              *ngIf="criterion.datapoint && criterion.datapoint.datatype == 'NUMERIC'"
              class="datapoint_icon datapoint_numbers_icon"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5.38623 16.5414V14.0098H7.56592V3.28162H5.25V0.75H11.0398V14.0098H13.106V16.5414H5.38623Z"
                fill="#374659"
              />
            </svg>
            <svg
              *ngIf="criterion.datapoint && criterion.datapoint.datatype == 'DATE'"
              class="datapoint_icon datapoint_date_icon"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5.0625 2.25V2.8125H2.8125V15.1875H15.1875V2.8125H12.9375V2.25H11.8125V2.8125H6.1875V2.25H5.0625ZM3.9375 3.9375H5.0625V4.5H6.1875V3.9375H11.8125V4.5H12.9375V3.9375H14.0625V5.0625H3.9375V3.9375ZM3.9375 6.1875H14.0625V14.0625H3.9375V6.1875ZM7.3125 7.3125V8.4375H8.4375V7.3125H7.3125ZM9.5625 7.3125V8.4375H10.6875V7.3125H9.5625ZM11.8125 7.3125V8.4375H12.9375V7.3125H11.8125ZM5.0625 9.5625V10.6875H6.1875V9.5625H5.0625ZM7.3125 9.5625V10.6875H8.4375V9.5625H7.3125ZM9.5625 9.5625V10.6875H10.6875V9.5625H9.5625ZM11.8125 9.5625V10.6875H12.9375V9.5625H11.8125ZM5.0625 11.8125V12.9375H6.1875V11.8125H5.0625ZM7.3125 11.8125V12.9375H8.4375V11.8125H7.3125ZM9.5625 11.8125V12.9375H10.6875V11.8125H9.5625Z"
                fill="#374659"
              />
            </svg>
            <svg
              *ngIf="criterion.datapoint && criterion.datapoint.datatype == 'BOOLEAN'"
              class="datapoint_icon datapoint_boolean_icon"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.42635 1.78895C9.31384 1.67647 9.16125 1.61328 9.00215 1.61328C8.84306 1.61328 8.69047 1.67647 8.57795 1.78895L1.78955 8.57615C1.73368 8.63189 1.68935 8.6981 1.6591 8.77099C1.62885 8.84389 1.61328 8.92203 1.61328 9.00095C1.61328 9.07987 1.62885 9.15802 1.6591 9.23091C1.68935 9.30381 1.73368 9.37002 1.78955 9.42575L8.57795 16.213C8.69047 16.3254 8.84306 16.3886 9.00215 16.3886C9.16125 16.3886 9.31384 16.3254 9.42635 16.213L16.2148 9.42575C16.2706 9.37002 16.315 9.30381 16.3452 9.23091C16.3755 9.15802 16.391 9.07987 16.391 9.00095C16.391 8.92203 16.3755 8.84389 16.3452 8.77099C16.315 8.6981 16.2706 8.63189 16.2148 8.57615L9.42635 1.78895ZM9.00155 3.06095L3.06155 9.00095L9.00155 14.941V3.06095Z"
                fill="#374659"
              />
            </svg>
            <select
              [(ngModel)]="criterion.datapoint"
              [compareWith]="datapointCompareWith"
              class="input datapoint_input"
            >
              <option selected disabled>
                {{ locale('locale_key.condition_container.select_datapoint') }}
              </option>
              <option [ngValue]="datapoint" *ngFor="let datapoint of getOtherDatapoints()">
                {{ datapoint.getLabel() }}
              </option>
            </select>
          </div>

          <div class="condition_input_container" [class.no_condition_selected]="!criterion.condition">
            <ng-container *ngIf="!criterion.datapoint">
              <select>
                <option selected disabled>
                  {{ locale('locale_key.condition_container.select_condition') }}
                </option>
              </select>
            </ng-container>

            <!-- TEXT CONDITIONS -->
            <ng-container *ngIf="criterion.datapoint && criterion.datapoint.datatype == 'STRING'">
              <select [(ngModel)]="criterion.condition">
                <option selected disabled>
                  {{ locale('locale_key.condition_container.select_condition') }}
                </option>
                <option value="is_exactly">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_equal_to') }}
                </option>
                <option value="is_empty">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_empty') }}
                </option>
                <option value="is_not_empty">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_not_empty') }}
                </option>
                <option value="contains">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.contains') }}
                </option>
              </select>
            </ng-container>

            <!-- NUMBERS CONDITIONS -->
            <ng-container *ngIf="criterion.datapoint && criterion.datapoint.datatype == 'NUMERIC'">
              <select [(ngModel)]="criterion.condition">
                <option selected disabled>
                  {{ locale('locale_key.condition_container.select_condition') }}
                </option>
                <option value="is_exactly">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_exactly') }}
                </option>
                <option value="is_less_than">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_less_than') }}
                </option>
                <option value="is_more_than">
                  {{
                    locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_greater_than')
                  }}
                </option>
                <option value="is_in_between">{{ locale('locale_key.condition_container.is_in_between') }}</option>
                <option value="is_empty">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_empty') }}
                </option>
                <option value="is_not_empty">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_not_empty') }}
                </option>
              </select>
            </ng-container>

            <!-- DATE CONDITIONS -->
            <ng-container *ngIf="criterion.datapoint && criterion.datapoint.datatype == 'DATE'">
              <select [(ngModel)]="criterion.condition">
                <option selected disabled>
                  {{ locale('locale_key.condition_container.select_condition') }}
                </option>
                <option value="is_exactly">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_exactly') }}
                </option>
                <option value="is_before">{{ locale('locale_key.condition_container.is_before') }}</option>
                <option value="is_after">{{ locale('locale_key.condition_container.is_after') }}</option>
                <option value="is_in_between">{{ locale('locale_key.condition_container.is_in_between') }}</option>
                <option value="is_empty">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_empty') }}
                </option>
                <option value="is_not_empty">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_not_empty') }}
                </option>
              </select>
            </ng-container>

            <!-- BOOLEAN CONDITIONS -->
            <ng-container *ngIf="criterion.datapoint && criterion.datapoint.datatype == 'BOOLEAN'">
              <select [(ngModel)]="criterion.condition">
                <option selected disabled>
                  {{ locale('locale_key.condition_container.select_condition') }}
                </option>
                <option value="true">{{ locale('locale_key.condition_container.is_yes') }}</option>
                <option value="false">{{ locale('locale_key.condition_container.is_no') }}</option>
              </select>
            </ng-container>
          </div>

          <div *ngIf="criterion.datapoint && criterion.datapoint.datatype != 'BOOLEAN'" class="value_input_container">
            <!-- TEXT CONDITIONS -->
            <input
              *ngIf="
                criterion.datapoint &&
                criterion.datapoint.datatype == 'STRING' &&
                criterion.condition != 'is_empty' &&
                criterion.condition != 'is_not_empty'
              "
              [(ngModel)]="criterion.value"
              type="text"
              class="input value_input"
            />

            <!-- NUMBERS CONDITIONS -->
            <input
              *ngIf="
                criterion.datapoint &&
                criterion.datapoint.datatype == 'NUMERIC' &&
                criterion.condition != 'is_empty' &&
                criterion.condition != 'is_not_empty'
              "
              [(ngModel)]="criterion.value"
              type="number"
              step="any"
              class="input value_input"
            />

            <!-- DATE CONDITIONS -->
            <input
              *ngIf="
                criterion.datapoint &&
                criterion.datapoint.datatype == 'DATE' &&
                criterion.condition != 'is_empty' &&
                criterion.condition != 'is_not_empty'
              "
              [(ngModel)]="criterion.value"
              type="date"
              class="input value_input"
            />
          </div>

          <div
            *ngIf="criterion.datapoint?.datatype == 'DATE' && criterion.condition == 'is_in_between'"
            class="value_input_container"
          >
            <input ng-required="true" [(ngModel)]="criterion.toValue" type="date" class="input value_input" required />
          </div>

          <div
            *ngIf="criterion.datapoint?.datatype == 'NUMERIC' && criterion.condition === 'is_in_between'"
            class="value_input_container"
          >
            <input
              ng-required="true"
              [(ngModel)]="criterion.toValue"
              type="number"
              class="input value_input"
              required
            />
          </div>

          <i class="la la-trash-alt edit_control delete" (click)="removeCriterion({ index: criterionIdx })"></i>
        </div>
        <div
          class="add_criterion_control"
          [attr.data-criterionIdx]="criterionIdx"
          (click)="toggleCriterionDropdown({ criterionIdx, componentID, evt: $event })"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M13.6641 6.5H9.16406V2C9.16406 1.44781 8.71625 1 8.16406 1H7.16406C6.61188 1 6.16406 1.44781 6.16406 2V6.5H1.66406C1.11187 6.5 0.664062 6.94781 0.664062 7.5V8.5C0.664062 9.05219 1.11187 9.5 1.66406 9.5H6.16406V14C6.16406 14.5522 6.61188 15 7.16406 15H8.16406C8.71625 15 9.16406 14.5522 9.16406 14V9.5H13.6641C14.2163 9.5 14.6641 9.05219 14.6641 8.5V7.5C14.6641 6.94781 14.2163 6.5 13.6641 6.5Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="add_criterion_dropdown" [attr.data-criterionIdx]="criterionIdx">
          <div class="add_criterion_dropdown_content">
            <div class="title">
              {{ locale('locale_key.pages.emission.add_more_conditions_this_level') }}
            </div>
            <div class="add_comparator_container">
              <div class="add_and_btn" (click)="addCriterion({ comparator: 'and', index: criterionIdx })">
                <i class="icon las la-plus-circle"></i>
                <span class="label">{{ locale('locale_key.condition_container.add_and') }}</span>
              </div>
              <div class="add_or_btn" (click)="addCriterion({ comparator: 'or', index: criterionIdx })">
                <i class="icon las la-plus-circle"></i>
                <span class="label">{{ locale('locale_key.condition_container.add_or') }}</span>
              </div>
            </div>
            <div class="separator"></div>
            <div class="remove_btn" (click)="removeCriterion({ index: criterionIdx })">
              <i class="icon las la-trash-alt"></i>
              <span class="label">{{ locale('locale_key.general.buttons.remove') }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
