import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { TaxonomyAttributeFe } from 'src/app/model/taxonomy/TaxonomyAttributeFe'
import {
  Calculation,
  CalculationThenOptions,
  CloseBracketOperator,
  FixedNumberOperand,
  OpenBracketOperator,
  TimesOperator
} from '../model/CalculationBuilderModels'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { UnitFe } from 'src/app/components/unit-systems/model/UnitFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import _ from 'lodash'
import * as UUID from 'uuid'
import Big from 'big.js'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { DatapointDatatype } from 'src/app/model/taxonomy/DatapointDatatypeFe'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { UnitServiceFe } from 'src/app/services/UnitServiceFe'

@Component({
  selector: 'formula-builder',
  templateUrl: './formula-builder.component.html',
  styleUrls: ['./formula-builder.component.scss']
})
export class FormulaBuilderComponent extends AbstractLanguageComponent implements OnInit, AfterViewChecked {
  @Input() showEditMode: boolean = true
  @Input() showAddNewDataPointBtn: boolean = true
  @Input() showCalculationErrors: boolean = false
  @Input() showDatapoints: boolean = true
  @Input() selectedDatapoint: TaxonomyAttributeFe
  @Input() selectedDataCategory
  @Input() calculation: Calculation = {
    isFallback: false,
    formula: [],
    then: CalculationThenOptions.FORMULA
  }
  @Input() includeMeasurementTypes: Set<any> = new Set()

  errorMsgs = new Set()
  componentID
  taxonomies: {
    depTaxonomy: TaxonomyInfoFe
    newTaxonomy: TaxonomyInfoFe
  }

  checkedExpression: String = ''
  checkedTokenSize: number = 0

  constructor(
    private stateService: StateServiceFe,
    public languageService: LanguageService,
    private sanitizer: DomSanitizer,
    private unitService: UnitServiceFe
  ) {
    super(languageService)
  }

  ngAfterViewChecked(): void {
    //TODO: check performance
    const exp = this.formulaToExpression({ formula: this.calculation.formula })
    if (this.checkedExpression != exp || this.checkedTokenSize != this.calculation.formula.length) {
      this.checkFormulaForErrors()
      this.checkedExpression = exp
      this.checkedTokenSize = this.calculation.formula.length
    }
  }

  private formulaToExpression({ formula, harmonize = false }) {
    let exp = ''
    formula.forEach((token) => {
      switch (token.type) {
        case 'operand':
          switch (token.operandType) {
            case 'selectDataInput':
              exp += ''
              break
            case 'dataPoint':
              switch (token.datapoint?.datatype) {
                case 'NUMERIC':
                  let symbol = `${token.datapoint?.unit?.symbol ?? ''}`
                  if (symbol.includes('/')) {
                    symbol = `(${symbol})`
                  }
                  exp += `1${symbol}`
                  break
                case 'EMISSION_FACTOR':
                  const ef: any = token.datapoint?.emissionFactors[0]?.value

                  let sourceUnit = `${ef?.sourceUnit}`
                  if (sourceUnit.includes('/')) {
                    sourceUnit = `(${sourceUnit})`
                  }

                  let conversionUnit = `${ef?.conversionUnit}`
                  if (conversionUnit.includes('/')) {
                    conversionUnit = `(${conversionUnit})`
                  }

                  exp += `1${conversionUnit}/${sourceUnit}`
                  break
              }
              break
            case 'emissionFactor':
              const ef: any = token.emissionFactor

              let sourceUnit = `${ef?.sourceUnit}`
              if (sourceUnit.includes('/')) {
                sourceUnit = `(${sourceUnit})`
              }

              let conversionUnit = `${ef?.conversionUnit}`
              if (conversionUnit.includes('/')) {
                conversionUnit = `(${conversionUnit})`
              }

              exp += `1${conversionUnit}/${sourceUnit}`
              break
            case 'fixedNumber':
              if (harmonize) {
                exp += '1'
              } else {
                let symbol = `${token.unit?.symbol ?? ''}`
                if (symbol.includes('/')) {
                  symbol = `(${symbol})`
                }
                exp += `${token.value}${symbol}`
              }
              break
          }
          break
        case 'operator':
          switch (token.operatorType) {
            case 'control':
              exp += ''
              break
            case 'plus':
              exp += ' + '
              break
            case 'minus':
              exp += ' - '
              break
            case 'times':
              exp += ' * '
              break
            case 'divide':
              exp += ' / '
              break
            case 'openBracket':
              exp += '('
              break
            case 'closeBracket':
              exp += ')'
              break
          }
          break
      }
    })
    return exp
  }

  private formulaHasTokenWithUnit() {
    let tokenWithUnitFound = false

    this.calculation.formula.forEach((token) => {
      switch (token.type) {
        case 'operand':
          switch (token.operandType) {
            case 'dataPoint':
              if (!_.isEmpty(token.datapoint?.unit?.symbol)) {
                tokenWithUnitFound = true
              }
              break
            case 'fixedNumber':
              if (!_.isEmpty(token.unit?.symbol)) {
                tokenWithUnitFound = true
              }
              break
            default:
              break
          }
          break
      }
    })

    return tokenWithUnitFound
  }

  private checkFormulaForErrors() {
    this.errorMsgs.clear()
    let prevToken = undefined
    let bracketCounter = {
      open: 0,
      close: 0
    }

    this.calculation.hasError = false

    if (_.isEmpty(this.calculation.formula)) {
      this.calculation.hasError = true
      this.errorMsgs.add(this.locale('locale_key.pages.data_categories_formula.build_formula'))
    }

    this.calculation.formula.forEach((token, idx) => {
      this.calculation.formula[idx].highlightError = false
      //If token is a bracket, increment brackets
      if (token.type == 'operator' && token.operatorType == 'openBracket') {
        bracketCounter.open++
      } else if (token.type == 'operator' && token.operatorType == 'closeBracket') {
        bracketCounter.close++
      }

      //If it's the first token
      if (!prevToken) {
        prevToken = token

        // if it's a control and it's also the last
        if (token.type == 'operator' && token.operatorType == 'control' && idx == this.calculation.formula.length - 1) {
          this.calculation.formula[idx].highlightError = true
          this.errorMsgs.add(this.locale('locale_key.general.validation_message.delete_last_plus'))
        }
        return
      }

      // operands & operators of same type shouldn't be after one another
      if (prevToken.type == token.type) {
        if (
          token.type == 'operator' &&
          ((prevToken.operatorType == 'openBracket' && token.operatorType == 'openBracket') ||
            (prevToken.operatorType == 'closeBracket' && token.operatorType == 'closeBracket'))
        ) {
          /*
            In some situations, two open brackets or closing brackets
            being next too each other might be a valid formula
          */
        } else if (
          (token.type == 'operator' && token.operatorType == 'openBracket') ||
          (token.type == 'operator' && token.operatorType == 'closeBracket') ||
          (prevToken.type == 'operator' && prevToken.operatorType == 'openBracket') ||
          (prevToken.type == 'operator' && prevToken.operatorType == 'closeBracket')
        ) {
          /*
            Generally ignore brackets for now
          */
        } else {
          this.calculation.formula[idx].highlightError = true
          switch (token.type) {
            case 'operand':
              this.errorMsgs.add(this.locale('locale_key.general.validation_message.missing_operator_between'))
              break
            case 'operator':
              if (token.operatorType != 'control') {
                this.errorMsgs.add(
                  `${this.locale('locale_key.general.validation_message.missing_operand')} [${prevToken.operatorType}, ${token.operatorType}]`
                )
              }
              break
          }
        }
      }

      // control operator
      if (token.type == 'operator' && token.operatorType == 'control') {
        this.calculation.formula[idx].highlightError = true
        if (idx == this.calculation.formula.length - 1) {
          this.errorMsgs.add(this.locale('locale_key.general.validation_message.delete_last_plus'))
        } else {
          this.errorMsgs.add(this.locale('locale_key.general.validation_message.missing_operator_between'))
        }
      }

      // empty data input
      if (token.type == 'operand' && token.operandType == 'selectDataInput' && token.value == 'Select data input') {
        this.calculation.formula[idx].highlightError = true
        this.errorMsgs.add(this.locale('locale_key.general.validation_message.empty_data_point_field'))
      }

      // divide by zero
      if (
        prevToken.type == 'operator' &&
        prevToken.operatorType == 'divide' &&
        token.type == 'operand' &&
        token.value == '0'
      ) {
        this.calculation.formula[idx].highlightError = true
        this.errorMsgs.add(this.locale('locale_key.general.validation_message.divide_by_zero'))
      }

      prevToken = token
    })

    /* Check if brackets don't match */
    if (bracketCounter.open > bracketCounter.close) {
      this.calculation.hasError = true
      this.errorMsgs.add(this.locale('locale_key.general.validation_message.missing_closing_bracket'))
    } else if (bracketCounter.close > bracketCounter.open) {
      this.calculation.hasError = true
      this.errorMsgs.add(this.locale('locale_key.general.validation_message.missing_opening_bracket'))
    }

    //Check calculation is possible
    const calculationFormula = this.formulaToExpression({ formula: this.calculation.formula })
    const calcEvaluator = this.stateService.getUnitEvaluator()
    try {
      calcEvaluator.evaluate(calculationFormula)
    } catch (err) {
      this.calculation.hasError = true
      this.errorMsgs.add(this.locale('locale_key.pages.data_categories_formula.calculation_not_possible_as_defined'))
    }

    // Only check units are compatible if :
    // 1: Target unit has a unit ( skip if no unit or % )
    // 2: Atleast one token in the formula has a unit
    if (
      this.selectedDatapoint &&
      this.selectedDatapoint?.unit?.symbol &&
      !_.isEqual(this.selectedDatapoint?.unit?.symbol, '%') &&
      !_.isEmpty(this.calculation.formula) &&
      this.formulaHasTokenWithUnit()
    ) {
      const exp = this.formulaToExpression({ formula: this.calculation.formula })
      const targetUnit = this.selectedDatapoint?.unit?.symbol
      const compatibilityExp = `(${exp}) to ${targetUnit}`
      const unitEvaluator = this.stateService.getUnitEvaluator()
      try {
        unitEvaluator.evaluate(compatibilityExp)
      } catch (err) {
        this.calculation.hasError = true
        this.errorMsgs.add(this.locale('locale_key.general.error.message.unit_of_calculations_not_compatible'))
      }
    }

    if (!this.calculation.hasError) {
      // Formula Error check passed, generate harmonized formula
      this.harmonizeFormula()
    }
  }

  // This needs to happen on the frontend because we have the units for the workspace here
  private harmonizeFormula() {
    const harmonizedFormula = []
    this.calculation.formula.forEach((token) => {
      harmonizedFormula.push(token)
    })

    const unitEvaluator = this.stateService.getUnitEvaluator()

    if (
      !_.isEmpty(this.selectedDatapoint?.unit?.symbol) &&
      !_.isEqual(this.selectedDatapoint?.unit?.symbol, '%') &&
      !_.isEmpty(this.calculation?.formula)
    ) {
      const exp = this.formulaToExpression({ formula: this.calculation.formula, harmonize: true })
      const evaluatedExp = unitEvaluator.evaluate(exp).toString()

      const toUnit = this.selectedDatapoint.unit?.symbol
      const conversionString = `${evaluatedExp} to ${toUnit}`

      const conversionResult = unitEvaluator.evaluate(conversionString).toString()
      const conversionResultTokens = conversionResult.split(' ')
      // we use Big.js to handle scenarios where math.js returns values in scientific notation
      let conversionFactor = new Big(conversionResultTokens[0]).toString()
      if (conversionResultTokens[1] == toUnit) {
        conversionFactor = `1`
      }

      // wrap formula <f> in conversion => ( <f> ) * <global conversion factor> to get final target datapoint conversion
      const openBracket = new OpenBracketOperator()
      const times = new TimesOperator()
      const noUnit = this.unitService.getNoUnit()
      const conversionFactorToken = new FixedNumberOperand({ value: conversionFactor, unit: noUnit })
      const closeBracket = new CloseBracketOperator()

      harmonizedFormula.unshift(openBracket)
      harmonizedFormula.push(closeBracket)
      harmonizedFormula.push(times)
      harmonizedFormula.push(conversionFactorToken)
    }

    this.calculation.harmonizedFormula = harmonizedFormula
  }

  async ngOnInit() {
    this.componentID = UUID.v4()
    this.taxonomies = await this.stateService.getTaxonomyInfos()
  }

  resolveLabel({ token, deployed }) {
    let datapoint = token.datapoint

    if (deployed) {
      this.taxonomies?.depTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    } else {
      this.taxonomies?.newTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    }

    let result = ''

    if (
      datapoint &&
      datapoint.label &&
      datapoint.label.hasOwnProperty(this.languageService.getDisplayActiveLanguage())
    ) {
      result = datapoint.label[this.languageService.getDisplayActiveLanguage()]
    }

    if (result) {
      return result
    }

    return datapoint?.label['en'] ?? ''
  }

  resolveUnit({ token, deployed }) {
    let datapoint = token.datapoint

    if (deployed) {
      this.taxonomies?.depTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    } else {
      this.taxonomies?.newTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    }

    let unit = ''
    if (datapoint.datatype == DatapointDatatype.EMISSION_FACTOR) {
      const ef = (datapoint.emissionFactors[0] || { value: datapoint.emissionFactor } || { value: {} })
        .value as AbstractEmissionFactorFe

      let sourceUnit = `${ef?.sourceUnit}`
      if (sourceUnit.includes('/')) {
        sourceUnit = `(${sourceUnit})`
      }

      let conversionUnit = `${ef?.conversionUnit}`
      if (conversionUnit.includes('/')) {
        conversionUnit = `(${conversionUnit})`
      }

      unit = `${conversionUnit}/${sourceUnit}`
    }

    if (datapoint.datatype != DatapointDatatype.EMISSION_FACTOR) {
      let symbol = `${datapoint?.unit?.symbol ?? ''}`
      if (symbol.includes('/')) {
        symbol = `(${symbol})`
      }
      unit = symbol
    }

    return unit
  }

  isTokenDropdownOpen({ tokenIdx, componentID }) {
    const openedDropdown = document.querySelector('.dropdown-menu.show')
    if (openedDropdown instanceof HTMLElement) {
      const openedTokenIdx = openedDropdown.dataset.tokenidx
      const openedComponentID = openedDropdown.dataset.componentid
      return openedTokenIdx == tokenIdx && openedComponentID == componentID
    }
    return false
  }

  toggleTokenDropdown({ tokenIdx, componentID }) {
    const token = document.querySelector(`.formula_builder_${componentID} .token[data-tokenidx='${tokenIdx}']`)
    const dropdown = document.querySelector(`.formula_builder_${componentID} .dropdown[data-tokenidx='${tokenIdx}']`)

    const tokenIsActive = token?.classList.contains('active')

    if (tokenIsActive) {
      token?.classList.remove('active')
      dropdown?.classList.remove('active')
    } else {
      token?.classList.add('active')
      dropdown?.classList.add('active')
    }
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
}
